<template>
  <div>
    <main class="gate login">
      <div class="together">
        <img src="/rallyhood-white.svg" />
        Together with purpose!
      </div>
      <div class="login-form">
        <form @submit.prevent="handleSubmit" ref="loginform">

          <p v-if="status.error" style="color:red;text-align:center">Login Error Occured</p>
          <p v-if="!status.error" style="color:white;text-align:center">-&nbsp;</p>
          <div class="form-group">
            <label for="email">Email Address</label>
            <input v-model="username" id="email" name="email" type="text" autocomplete="username" @click="scrollTop"/>
          </div>
          <div class="form-group">
            <label for="password">Password</label>
            <div class="password-input-container">
              <input v-model="password" :type="showPassword ? 'text' : 'password'" id="password" name="password" autocomplete="current-password" class="password-input"/>
              <a href="#" class="toggle-password" @click.prevent="togglePassword">
                {{ showPassword ? 'Hide' : 'Show' }}
              </a>
            </div>
          </div>
          <div class="actions">
            <button :disabled="false" :class="!status.loggingIn ? 'primary' : ''" type="submit" style="margin-bottom:15px;">
              Log In
            </button>
            <br/>
            <router-link class="link-button" to="/createAccount">Create Account</router-link>
            &nbsp;
            <router-link class="link-button" to="/resetpasswordemail">Reset Password</router-link>
            &nbsp;
            <a class="link-button" href="mailto:support@rallyhood.com">Contact Us</a>
          </div>
        </form>
      </div>
      <!--<hr />
      <div class="trouble">
        <div>
          Having trouble logging in?
        </div>
        <div>
          <router-link class="link-button" to="/resetpasswordemail">Reset Password</router-link>
          or
          <a class="link-button" href="mailto:support@rallyhood.com">Contact Us</a>
        </div>
      </div>-->
    </main>
  </div>
</template>

<script>
import { ref, computed, onMounted, onUnmounted, watch } from 'vue';
import { useStore } from 'vuex';
import { useRouter } from 'vue-router';
import { Capacitor } from '@capacitor/core';
import { userInfoPersistent } from '@/common/user-info';
import { RH_URL } from "../common";
import { App } from '@capacitor/app';
import { Storage } from '@capacitor/preferences';
import { Browser } from '@capacitor/browser'; 

export default {
  name: 'LoginPage',
  setup() {
    const store = useStore();
    const router = useRouter();
    const loginform = ref(null);
    const username = ref('');
    const password = ref('');
    const showPassword = ref(false);
    const screenWidth = ref(window.innerWidth);

    const status = computed(() => store.state.user.status);
    const user = computed(() => store.state.user.user);

    const togglePassword = () => {
      showPassword.value = !showPassword.value;
    };

    const handleResize = () => {
      screenWidth.value = window.innerWidth;
    };

    const handleSubmit = async () => {
      if (username.value && password.value) {
        await store.dispatch('user/login', { username: username.value.trim(), password: password.value.trim() });
      }
      scrollBack();
    };

    const scrollBack = () => {
      const login_form = loginform.value;
      if (login_form) {
        login_form.style = '';
      }
    };

    const scrollTop = () => {
      if (screenWidth.value < 960) {
        const login_form = loginform.value;
        if (login_form) {
          login_form.style = 'position: fixed; top: 25px; width: 100%';
        }
      }
    };

const setupListener = async () => {
    try {
      const user = await userInfoPersistent();
      if (Capacitor.getPlatform() != "web") {
        await store.dispatch('user/loadPushStuff', { user });
      }
    } catch (e) {
      console.log('error loading push stuff in login page', e);
    }
}
    /*const setupListener = async () => {
      App.addListener('appStateChange', async ({ isActive }) => {
        if (!isActive) {
          console.log("APP INACTIVE !!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!");
        } else {
          console.log("APP ACTIVE !!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!");
          const user = await userInfoPersistent();
          if (user) {
            localStorage.setItem('user', JSON.stringify(user));
            router.push('/rallies');
          }
        }
      });

      App.addListener('appUrlOpen', (event) => {
        const slug1 = event.url.split('rallyhood.com/').pop();
        try {
          if (slug1 && slug1.indexOf('users/password') >= 0) {
            Browser.open({ url: `${RH_URL()}${slug1}&cap_open=1` });
            Browser.addListener('browserFinished', () => {
              Browser.removeAllListeners();
              location.reload();
            });
          } else if (slug1 && slug1.indexOf('channel_key') >= 0) {
            Browser.open({ url: `${RH_URL()}${slug1}&cap_open=1` });
            Browser.addListener('browserFinished', () => {
              location.reload();
            });
          } else if (slug1) {
            setTimeout(() => {
              if (slug1.indexOf('?et=') >= 0) {
                Browser.open({ url: `${RH_URL()}${slug1}&cap_open=1` });
              } else if (slug1.indexOf('channel_key') >= 0) {
                const slug2 = slug1.split('?')[0].replace('fundraisers', 'special_events');
                router.push(slug2);
              } else {
                router.push(slug1);
              }
            }, 4000);
          }
        } catch (e) {
          console.log('deeplink: error', e);
          router.push('/rallies');
        }
      });

      try {
        const user = await userInfoPersistent();
        await store.dispatch('user/loadPushStuff', { user });
      } catch (e) {
        console.log('error loading push stuff in login page', e);
      }
    };*/

    const logout = async () => {
      localStorage.removeItem('at');
      localStorage.removeItem('user');
      //await Storage.remove({ key: 'user' });
    };

    onMounted(async () => {
      window.addEventListener('resize', handleResize);
      const user = await userInfoPersistent();
      if (user) {
        localStorage.setItem('user', JSON.stringify(user));
      } else {
        await logout();
      }
    });

    onUnmounted(() => {
      setupListener();
      window.removeEventListener('resize', handleResize);
    });

    watch(user, (newval) => {
      if (newval) {
        router.push('/rallies');
      }
    });

    return {
      username,
      password,
      showPassword,
      screenWidth,
      status,
      togglePassword,
      handleSubmit,
      scrollBack,
      scrollTop,
    };
  }
};
</script>

<style scoped>
.password-input-container {
  position: relative;
  display: flex;
  align-items: center;
}

.password-input {
  flex-grow: 1;
  padding-right: 60px; /* Adjust as needed for space for the toggle link */
}

.toggle-password {
  color: #008295;
  position: absolute;
  right: 10px; /* Adjust as needed for exact positioning */
  cursor: pointer;
  padding: 5px;
  text-decoration: none;
}
</style>
