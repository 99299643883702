<template>
  <div>
  <main>
    <section>
      <div style="margin-top: 90px;" >
        <ActivityFeedContent
            v-for="content in activityFeed"
            v-on:perform="perform"
            :channelId="content['channel-id']"
            :content="content"
            :contentId="content.id"
            :key="content.id"
            :rallyId="content.id"
            :observer = observer
        />
      </div>
      <infinite-loading ref="infLoad" @infinite="infiniteHandler">
        <span slot="complete"> <br/> <i @click="reloadPage" class="fa fa-sync" ><div style="font-family:sans-serif;">Refresh</div></i><br/><br/><br/></span>
        <span slot="no-results"> <br/> <i @click="reloadPage" class="fa fa-sync" ><div style="font-family:sans-serif;">Refresh</div></i><br/><br/><br/></span>
      </infinite-loading>
    </section>
  </main>
  </div>
</template>

<script>

import { mapState, mapActions, mapGetters } from "vuex";
import {
  router,
  slugToTypeObjectMap,
  findDefaultChannelOfTypeForRally,
  findDefaultChannelObjOfType,
  kebabCase
} from "../common";
import { userInfo } from "../common/user-info";
import _ from 'lodash'
import { eventBus } from "../main.js";
export default {
  data() {
    return {
      isComplete:false,
      loading: false,
      currentPhoto: null,
      loadAttempted: false,
      prevLoadCount: 0,
      prevLoadCountSameCount: 0,
      pageLoad: 1,
      // channelId: 0,
      contextId: 0,
      contextType: "Channel",
      observer: null,
      pageMap: [],
      pageNumber: 1,
      parentContainers: [],
      parentContextIds: [],
      parentContextTypes: [],
      rhWebParams: null,
      uploadProgress: 0,
    };
  },
  computed: {
    ...mapState("activities", ["activityFeed", "isLoading", "endOfActivity"]),

    totalCount() {
      return this.activityFeed.total_count
    },
    back() {
      // Determine whether or not to display the "back" arrow in the toolbar.
      return this.parentContextIds && this.parentContextIds.length > 0 ? "navUp" : "";
    },
    currentContent() {
      return this.parentContainers ? this.parentContainers[this.parentContainers.length - 1] : null
    },
    feed() {
      return this.activityFeed
    },
    isEmpty() {
      return this.activityFeed && this.activityFeed.length == 0
    },
  },
  beforeMount() {
    this.loadFeed();
  },
  mounted() {
    // Initially load some items.
    this.loadNextPage();
  },
  methods: {
    ...mapActions("activities", ["contentViewed", "fetchActivityFeed"]),
    reloadPage(){
      location.reload()
    },
    showLightbox(image) {
      this.currentPhoto = image;
    },
    handleLightboxHide() {
      this.currentPhoto = null;
    },
    async infiniteHandler($state) {
      let beforesize = this.contents.length
      await this.loadNextPage();
      if (beforesize == this.contents.length){
        this.isComplete = true;
        $state.complete();
      } else {
        $state.loaded();
      }
    },
    loadFeed() {
      setInterval(function () {
        if(!this.isLoading) {
          this.pageNumber = 1
          console.log("***** REFRESH FETCHING PAGE 1: " + this.pageNumber + " of Activity Feed ******")
          this.fetchActivityFeed({
            pageNumber: this.pageNumber
          });
        }
      }.bind(this), 500000);
      return this.fetchActivityFeed({
        pageNumber: 1
      });
    },
    async loadNextPage() {
      this.pageNumber = this.pageNumber + 1
      await this.fetchActivityFeed({
        pageNumber: this.pageNumber
      })
      /*window.onscroll = () => {
        //console.log('SCROLL TOP:::::')
        //console.log(document.documentElement.scrollTop)
        //console.log("WINDOW INNERHEIGHT")
        //console.log(window.innerHeight)
        //console.log("OFFSET HEIGHT")
        //console.log(document.documentElement.offsetHeight)
        let bottomOfWindow = document.documentElement.scrollTop + window.innerHeight + 2 >= document.documentElement.offsetHeight;
        if (bottomOfWindow) {
          //console.log("AT THE BOTTOM!!")
          setTimeout(e => {
            //console.log("LOADING.... " + this.isLoading)
            if(!this.isLoading && !this.endOfActivity)
            {
              this.pageNumber = this.pageNumber + 1
              console.log("***** FETCHING PAGE: " + this.pageNumber + " of Activity Feed ******")
              await this.fetchActivityFeed({
                pageNumber: this.pageNumber
              })
            }
          }, 1000);
        }
      }*/
    },
    onElementObserved(entries) {
      entries.forEach(({target, isIntersecting}) => {
        if (!isIntersecting) {
          return;
        }
        let contentId = target?.attributes?.contentid?.value;
        let contentType = target?.attributes?.contenttype?.value;
        if (contentId && contentType) {
          let params = {}
          params['contentId'] = contentId
          params['contentType'] = contentType
          params['actionType'] = 'viewed'
          params['rallyId'] = this.rallyId
          params['userId'] = userInfo()['id']
          this.contentViewed(params);
        }
      })
    },
    showFilez() {
      this.$refs.filePicker.click();
    },
    followLink(theUrl) {
      window.open(theUrl, "_blank");
    },
    navUp() {
      this.parentContainers.pop();
      this.contextId = this.parentContextIds.pop();
      this.contextType = this.parentContextTypes.pop();
    },
    openContent(content) {
      console.log("OPEN CONTENT")
      console.log(content)
      const contentId = content.id;
      ///const path = `/${content['context-share']['context-rally-id']}/${content['context-share']['context-channel-id']}/${contentId}`;
      const path = (`/${content.attributes['rally-id']}/${content.attributes['channel-slug']}/${content.attributes['activity-id']}`);
      //router.push(path);
      if (this.loadPageData) {
        this.loadPageData();
      }
    },
    perform(method, params) {
      params ? this[method](params) : this[method]();
    }
  }
};
</script>
