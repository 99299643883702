<template>
  <div class="avatar-upload" v-if="changingAvatar">
    <span class="avatar-upload-wrapper">
      <div v-if="!this.imgData && !this.avatarImage" :style="'margin-top: 35px; margin-left: 285px; ' + spiritColor + '; width: ' + pSize + '; height: ' + pSize + '; font-size: ' + fontSizeComputed + '; line-height: ' + lineHeightComputed" class="initials"  >
        {{ initials }}
      </div>
      <img v-if="!this.imgData && !this.avatarImage" src=""/>
      <cropper
          v-if="this.imgData || this.avatarImage"
          :src="currentUserImgUrl"
          :minWidth="300"
          :minHeight="300"
          :stencil-props="{
          aspectRatio: 1 / 1
        }"
          @change="cropperChange"
      ></cropper>
      <div class="avatar-upload" style="margin-bottom: 25px;">
        <image-uploader
            :quality="1.0"
            :maxWidth="773"
            :maxHeight="773"
            outputFormat="verbose"
            :autoRotate="false"
            :preview="false"
            :capture="false"
            :className="'file-input-label'"
            accept="image/*"
            doNotResize="['gif', 'svg', 'jpg', 'png', 'jpeg']"
            @input="cacheAvatar"
        >
          <label for="fileInput" slot="upload-label">
            <span class="upload-caption">
              <i class="fa fa-camera"></i>
              {{ 'NEW PHOTO' }}
            </span>
          </label>
        </image-uploader>
        <button class="cancel-button" @click="cancelAvatarChange">Cancel</button>
      </div>

    </span>
  </div>
  <div v-else>
    <div class="accordion-header">
      <button class="primary change-image" @click="changingAvatar = true">
        <i class="fa fa-photo"></i>
        Change Image
      </button>
    </div>
    <component
        :is="elementType"
        class="profile-avatar"
        :src="imgData"
    >

      <div v-if="imageMissing" :style="spiritColor + '; width: ' + pSize + '; height: ' + pSize + '; font-size: ' + fontSizeComputed + '; line-height: ' + lineHeightComputed" class="initials"  >
        {{ initials }}
      </div>

      <img v-if="imagePresent"
           :src="imgData"
           class="profile-avatar"
      />
    </component>
  </div>
</template>

<script>
import { mapState, mapActions, mapGetters } from "vuex";
import { eventBus } from "../../main.js";
import {
  router,
  kebabCase, avaUrl, originalImgUrl
} from "../../common";
import { Cropper } from 'vue-advanced-cropper';
import {userInfo} from "@/common/user-info";
export default {
  name: 'UserAvatarUpload',
  components: {
    Cropper // Register the advanced-cropper component
  },
  data() {
    return {
      uploadProgress: 0,
      changingAvatar: false,
      newUserImgUrl: null,
      imgData: null,
      imgParams: {},
      tempKey: "TK" + Math.floor(Math.random() * 10000)
    };
  },
  props: ["pUserId", "type", "pMembership", "pRallyId", "pSize", "pFontSize", "pLineHeight", "uniqueId"],
  computed: {
    ...mapState("contents", ["uploadCount", "allContentByContextTypeAndId", "contentById"]),
    ...mapState("user", ["usersById"]),
    currentUser() {
      return this.usersById[this.user_id];
    },
    avatarImage() {
      return this.userAvatarContent
    },
    elementType() {
      return "div"
    },
    fontSizeComputed() {
      if (this.pFontSize) {
        return this.pFontSize;
      } else {
        return "1.8rem";
      }
    },
    lineHeightComputed() {
      if (this.pLineHeight) {
        return this.pLineHeight;
      } else {
        return "3.2rem";
      }
    },
    imageMissing() {
      return !this.userAvatarFile || this.userAvatarFile.indexOf("missing.png") >= 0;
    },
    imagePresent() {
      return this.userAvatarFile && this.userAvatarFile.indexOf("missing.png") < 0;
    },
    initials() {
      let firstInitial = ""
      let lastInitial = ""
      try{
        firstInitial =  (this?.currentUser?.attributes["first-name"] && this.currentUser.attributes["first-name"].length > 0) ?  this.currentUser.attributes["first-name"][0] : "";
        lastInitial =  (this?.currentUser?.attributes["last-name"] && this?.currentUser?.attributes["last-name"].length > 0) ? this.currentUser.attributes["last-name"][0] : "";
        if (this.membership && this.membership.attributes){
          firstInitial = this.membership.attributes["first-name"] ? this.membership.attributes["first-name"][0] : firstInitial
          lastInitial = this.membership.attributes["last-name"] ? this.membership.attributes["last-name"][0] : lastInitial
        }
      } catch (e)
      {
        console.log(e)
      }
      return firstInitial + lastInitial
    },
    spiritColor() {
      const colors = ["#40B5BC", "#5AC6C6", "#8FD0CA", "#03DACE", "#42ABC5"];
      if (this.currentUser && this.currentUser.id ){
        const spiritNumber = Math.floor(parseInt(this.currentUser.id.split("").pop()) / 2);
        return `background-color: ${colors[spiritNumber]};`;
      } else {
        return `background-color: ${colors[0]};`;
      }
      //return "#40B5BC"
    },
    /*userAvatarContent() {
      if (this?.currentUser?.attributes?.avatar){
        return this.currentUser.attributes.avatar
      } else if ( !this?.currentUser?.relationships?.avatar?.data?.id ){
        return this.defaultAvatarsByType["user"]
      } else {
        return this.contentById[parseInt(this.currentUser.relationships.avatar.data.id)]
      }
    },*/
    userAvatarContent() {
      //var avid = parseInt(_user.relationships.avatar.data.id)
      if (this?.currentUser?.relationships?.avatar?.data?.id && this?.currentUser?.relationships?.avatar?.data?.id != "-1" && this.contentById[this.currentUser.relationships.avatar.data.id + ""]?.attributes){
        return this.contentById[this.currentUser.relationships.avatar.data.id + ""]
      }
      else if (this?.currentUser?.attributes?.avatar){
        return this.currentUser.attributes.avatar
      } else if ( !this?.currentUser?.relationships?.avatar?.data?.id ){
        return this.defaultAvatarsByType["user"]
      } else {
        return this.contentById[this.currentUser.relationships.avatar.data.id]
      }
    },
    userAvatarFile() {
      var _avatar = this.userAvatarContent
      if (typeof(_avatar) === 'string' || _avatar instanceof String){
        return _avatar
      }
      if (_avatar && _avatar['large-url'] || (_avatar && _avatar.attributes && _avatar.attributes['large-url'])) {
        var imgUrl = _avatar['large-url'] ? _avatar['large-url'] : _avatar.attributes['large-url']
        return (imgUrl && imgUrl.indexOf("https://") < 0) ? "https://" + imgUrl : imgUrl
      }
      return null;
    },
    currentUserImgUrl() {
      return this.newUserImgUrl
          ? this.newUserImgUrl
          : (this.avatarImage && this.avatarImage !== "missing.png" ? originalImgUrl(this.avatarImage) : "");
    },
    newImageName() {
      return this.imgData ? this.imgData.info.name : "";
    },
    progressBarStyle() {
      if ( this.uploadCount == 0 ) {
        return 'display: none;';
      } else {
        return "width: " + this.uploadProgress + "%;upload-count:" + this.uploadCount;
      }
    },
  },
  created() {
    this.loadPageData();
    if(this.avatarImage) {
      this.imgData = this.userAvatarFile
    }

    //this.imgData = this.rallyImgUrl
    eventBus.on("uploadAvatar" + this.uniqueId, p => {
      if (this.changingAvatar && this.imgData && this.imgData !== "missing.png" ){
      this.imgParams["contextType"] = 'User';
      this.imgParams["contextId"] = this.user_id;
      //Parameters: {"data"=>{"type"=>"contents", "attributes"=>{"attachment"=>"[FILTERED]", "creator_id"=>nil, "cropping"=>{"crop_h"=>"7", "crop_w"=>"20", "crop_x"=>"0", "crop_y"=>"10"}, "content_type"=>"Binary", "context_id"=>"51060", "context_type"=>"Rally", "status"=>1, "type"=>"RallyAvatar", "context_rally_id"=>"51060", "context_channel_id"=>nil, "attachment_file_name"=>"[FILTERED]", "attachment_content_type"=>"[FILTERED]"}}}
      this.uploadFile(this.imgParams);
      this.imgData = this.imgData.dataUrl
      let tempContentId =  "TK" + Math.random() * 100000000000000000
      this.contentById[tempContentId] = this.imgData
      console.log("current user is: " )
      console.log(this.currentUser)
      if (!this.currentUser.relationships){
        this.currentUser.relationships = {"avatar":{"data":{"id":tempContentId}}}
      }
      if (!this.currentUser.relationships.avatar){
        this.currentUser.relationships.avatar = {"data":{"id":tempContentId}}
      }
      this.currentUser.relationships.avatar.data.id = tempContentId
      this.changingAvatar = false;
      }
    });
  },
  watch: {
    allContentByContextTypeAndId: function(newById, oldById) {
      if (this.uploadProgress > 0) {
        this.uploadProgress = 95;
      }
    }
  },
  mounted() {
    // Get the input element using the ref attribute
    //const fileInput = this.$refs.fileInput;

    // Hide the input element
    //fileInput.style.display = "none";
  },
  methods: {
    ...mapActions("contents", ["fetchContents", "uploadFile"]),
    ...mapActions("shares", ["shareContent"]),
    async loadPageData() {
      this.user_id = userInfo().user_id;
    },
    cancelAvatarChange() {
      this.changingAvatar = false;
      // Clear the uploaded image data and URL
      this.imgData = this.userAvatarFile
      this.newUserImgUrl = null;
    },
    cropperChange({ coordinates, canvas }) {
      if (this.changingAvatar && this.imgData && this.imgData.info) {
        this.imgParams = {
          contentType: "Binary",
          contextType: "User",
          type: "UserAvatar",
          attachment: canvas.toDataURL().split(",")[1],
          attachmentDataURL: canvas.toDataURL(), 
          status: 1,
          attachmentContentType: (typeof this.imgData === 'string') ? this.parseFileType() : this.imgData.info.type, //this.imgData.info.type, //this.imgData.info ? this.imgData.info.type : (typeof this.avatarImage === 'string') ? "image" :  this.avatarImage.attributes['attachment-content-type'],
          attachmentFileName:   (typeof this.imgData === 'string') ? "img" + (Math.random() * 10000000) : this.imgData.info.name, //this.imgData.info.name,  // this.imgData.info ? this.imgData.info.name : (typeof this.avatarImage === 'string') ? : this.avatarImage.attributes['attachment-file-name'],
          tempKey: this.tempKey
        }
      }
    },
    
    cacheAvatar(img) {
      this.imgData = img;
      this.newUserImgUrl = img.dataUrl;
      this.changingAvatar = true
    },

    parseFileType(){
      return this.imgData.split("data:")[1].split(";")
    },
    displayMessageUploadProgress() {
      setTimeout(
          function() {
            if (this.uploadProgress <= 90) {
              this.uploadProgress += 3;
              this.displayMessageUploadProgress();
            } else {
              this.uploadProgress = 0;
            }
          }.bind(this),
          100
      );
    },
    perform(method, params) {
      params ? this[method](params) : this[method]();
    },
    showLightbox(image) {
      this.currentPhoto = image;
    },
  }
};
</script>
