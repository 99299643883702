<template>
  <div v-if="checkReloadData && !componentHideNav && !isLoading" id="rally-nav" :class="[{ 'open-info': openInfo }, channelPrimacy]">
    <div class="shadow" @click="closeRallyInfo"></div>
    <header id="rally-info">
      <div class="breadcrumbs" v-if="imaMemberOfParent">
        <a v-on:click="gotoParent()">{{ this.parentRallyTitle }}</a>
      </div>
      <img :src="justAvatarUrl" @error="rallyAvatarOnError" style="display:none"/>
      <div class="avatar" :style="avatarCss"></div>
      <div class="info">
        <div class="actions">
          <span v-if="selectedRally.attributes.editable"  class="action" @click="toggleInvitationDrawer">
            <i title="Invite" class="fal fa-user-plus"></i>
          </span>
          <router-link
            :to="{ name: 'rallySettings', params: { rallyId: rallyId }}"
            class="action"
          >
            <i class="fal fa-cog"></i>
          </router-link>
        </div>
        <div class="title">{{ selectedRally.attributes.title }}</div>
        <div class="cry">{{selectedRally && selectedRally && selectedRally.attributes["description"] }}</div>
        <div class="stats">
          <div class="stat">
            <i class="fal fa-users"></i>
            {{ Number(selectedRally.attributes["member-count"]).toLocaleString() }} members
          </div>
          <div v-if="selectedRally && selectedRally && selectedRally.attributes['child-count'] > 0" class="stat">
            <i class="fa-sitemap fal icon"></i>
            {{ selectedRally.attributes["child-count"] }} Rallies
          </div>
          <div v-if="selectedRally.attributes['editable']" class="stat">
            <i class="fa-user fal icon"></i>
            You have Rally Admin Access
          </div>
          <div v-else-if="selectedRally.attributes['writable']" class="stat">
            <i class="fa-user fal icon"></i>
            You have Rally Friend Access
          </div>
          <div v-else-if="selectedRally.attributes['viewable']" class="stat">
            <i class="fa-user fal icon"></i>
            You have Read Only Rally Friend Access
          </div>
          <div v-else class="stat">
            <i class="fa-user fal icon"></i>
            You are not a member of this rally
          </div>
          <div class="stat">
            <i class="fal fa-calendar-alt"></i>
           {{formatDate( selectedRally.attributes['created-at'])}}
          </div>
        </div>
      </div>
      <InvitationDrawer
        :state="invitationDrawerState"
        :rallyId="rallyId"
        @close="toggleInvitationDrawer"
      />
      <div v-if="thumbNavIsOpen" @click="toggleThumbNav" class="shadow" style="display: block;"></div>
      <ul class="channels" :class="[{ open: thumbNavIsOpen }]">
        <li class="more" @click="toggleThumbNav">
          <i class="fal fa-ellipsis-v" style="pointer-events: none;"></i>
          <span>More</span>
        </li>
        <li
          v-for="(channel, index) in channels"
          :key="channel.id"
          :class="[{ current: isCurrent(channel), on: channel.attributes.state }]"
          :data-slug="channel.attributes.slug"
          :style="'order: ' + (index + 1) + ';'"
          @click="navigateTo"
        >
          <i :class="'fal ' + channel.attributes.icon">  <span v-if="getChannelCount(channel)" class="badge">{{getChannelCount(channel)}}</span></i>
          {{ formatChannelName(channel.attributes.name) }}

        </li>
      </ul>
    </header>
  </div>
</template>

<script>
import moment from "moment";
import { mapState, mapActions, mapGetters } from "vuex";
import {router, slugToTypeObjectMap, typeToVueSlugMap, avaUrl, channelTypeObjectMap} from "../common";
import { userInfo } from "../common/user-info"
import InvitationDrawer from "./InvitationDrawer";
import RallyReloadMixin from "../components/RallyReloadMixin";  

export default {
  mixins: [RallyReloadMixin],
  components: {
    InvitationDrawer,
  },
  props: ["rally", "channel"],
  data: function() {
    return {
      invitationDrawerState: false,
      thumbNavIsOpen: false,
      loadAttempted: false,
      rallyAvatarLoaded: true,
      selectedRallyData: null,
      isLoading: true
    };
  },
  computed: {
    ...mapState("rallies", ["allRalliesByRallyId", "rallyChannelsByRallyId"]),
    ...mapState("contents", ["contentById", "defaultAvatarsByType"]),
    ...mapState("activities", ["channelCountsByRallyId"]),
    componentHideNav() {
        return document.getElementById("vue_component_name_id") != null
    },
    selectedRally: function() {
      return this.allRalliesByRallyId[parseInt(this.$route.params.rallyId)]?.rally;
    },
    parentRallyTitle() {
      return this.selectedRally.attributes.parent.title
      //return this.allRalliesByRallyId[this.selectedRally?.relationship?.attributes["left-rally-id"]]?.rally?.attributes?.title;
    },
    //grandpaRallyId() {
      //  return this.allRalliesByRallyId[this?.parentRallyId]?.relationship?.attributes["left-rally-id"]?.id;
    //},
    parentRallyId() {
      //return this.allRalliesByRallyId[this.selectedRally?.relationship?.attributes["left-rally-id"]]?.id;
      return  this.selectedRally && this.selectedRally.attributes.parent ? this.selectedRally.attributes.parent.id :  null
    },
    imaMemberOfParent(){
      return this.parentRally && this.parentRally.type === 'Standard' && this.allRalliesByRallyId[this.rallyId]?.rally?.attributes['parent-relationship-type'] != 'HiddenOrg'
    },
    parentRally() {
      return this.selectedRally &&
      this.selectedRally &&
      this.selectedRally.attributes &&
      this.selectedRally.attributes.parent ? this.selectedRally.attributes.parent : null
    },
    openInfo() {
      return this.$store.getters['rallies/showRallyInfo'] && this.channelPrimacy == 'subsequent';
    },
    rallyAvatarContent() {
      if (this.allRalliesByRallyId[this.rallyId]) {
      var avid = this.allRalliesByRallyId[this.rallyId].rally.relationships.avatar.data.id
      if ( avid === "-1" || !this.rallyAvatarLoaded){
        return this.defaultAvatarsByType["rally"]
      } else {
        return this.contentById[avid];
      }
      } else {
        return ""
      }

    },
    rallyId() {
      return this.rally.id
    },
    myRole() {
      return this.selectedRally.membership ? this.selectedRally.membership.attributes["role"] : "Friend";
    },

    avatarCss() {
      if (this.allRalliesByRallyId[this.rallyId]?.rally?.relationships?.avatar?.data?.id){//this.contentById[this.rallyId +  "_rally_avatar"]){
        return {
          backgroundColor: "white", //this.rally.avatar.background,
          backgroundImage: "url(" + this.contentById[this.allRalliesByRallyId[this.rallyId]?.rally?.relationships?.avatar?.data?.id]?.attributes['large-url'] + ")"
        }
      } else {
        return {
          backgroundColor: "white", //this.rally.avatar.background,
          backgroundImage: "url(" + avaUrl(this.rallyAvatarContent) + ")"
        }
      }

    },
    justAvatarUrl(){
      return avaUrl(this.rallyAvatarContent)
    },
    channelPrimacy() {
      // On mobile the first channel displays a rally info card, while later
      // channels display a "home" icon.  On desktop later channels display
      // a condensed version of the header.
      return (this.channels && this.channels.length &&
          (this.channelSlug == this.channels[0].attributes.slug) ) ? "first-channel" : "subsequent";
    },
    channelSlug() {
      // Used in place of channel.type.
      return this.$route.params.channelSlug;
    },
    badgeCountMap(){
      return this.channelCountsByRallyId[this.rallyId]
    },
    theSlugToObjectTypeMap() {
      return slugToTypeObjectMap()
    },
    channelType() {
      return this.channel.attributes.type
    },
    channelTypePlural() {
      return channelTypeObjectMap()[this.channelType].channelTypePlural;
    },
    contentType() {
      return channelTypeObjectMap()[this.channelType].contentType;
    },
    myType() {
      return channelTypeObjectMap()[this.channelType].type;
    },
    newAction() {
      return channelTypeObjectMap()[this.channelType].newAction;
    },
    parentPresent() {
      // TODO: Fix breadcrumbs for rallies with parent hubs
      return false;
    },
    channels() {
      var chans = this.rallyChannelsByRallyId[this.rallyId];
      if (chans && chans.length > 0) {
        return chans.filter(function(c) {
          return (
            c.attributes.type !== "RallySettings" &&
            c.attributes.type !== "Analytics" &&
            c.attributes.state !== "off"
          );
        });
      } else {
        return [];
      }
    }
  },
  async mounted() {
    await this.fetchSelectedRally();
    this.isLoading = false
  },
  methods: {
    ...mapActions("memberships", ["fetchMembersByRally"]),
    ...mapActions("contents", ["fetchContentById"]),
    ...mapActions("rallies", ["fetchRalliesWithInfo", "fetchRalliesChannels", "closeRallyInfo", "toggleRallyInfo"]),
    fetchSelectedRally() {
      // Asynchronous operation here
     // if (this.allRalliesByRallyId[this.rallyId] === undefined) {
     //   await this.fetchRalliesWithInfo({rallyObjs: null, pageNumber: 1, parentRallyId: null, rallyId: this.rallyId, rallyIds: null}).then(() => {
     //     this.selectedRallyData = this.allRalliesByRallyId[this.rallyId];
     //   });
     // }
      this.selectedRallyData = this.allRalliesByRallyId[this.rally.id];
    },

    getChannelCount(channel){
      if (this.channelCountsByRallyId[this.rallyId] === undefined) {
        return ""
      }

      if (channelTypeObjectMap()[channel.attributes.type] && channelTypeObjectMap()[channel.attributes.type].badgeCountName){
        return this.channelCountsByRallyId[this.rallyId][channelTypeObjectMap()[channel.attributes.type].badgeCountName]
      }

      return ""
    },
    rallyAvatarOnError(){
      this.rallyAvatarLoaded = false
    },
    isCurrent(channel) {
      // Am I me?
      return channel.attributes.slug == this.channelSlug;
    },
    navigateTo(event) {
      this.closeRallyInfo();
      this.thumbNavIsOpen = false;
      //const slug = typeToVueSlugMap()[event.target.attributes["data-slug"].value];
      const slug = event.target.attributes["data-slug"].value
      var thePath = `/${this.rallyId}/${slug}`
      if (this.$route.path != thePath) {
        router.push(thePath)
        this.$emit('perform', 'clearParentContainers')
        window.scrollTo(0, 0);
      }
    },
    formatChannelName(name){
      if (name && name.length > 14){
        return name.substring(0,10) + "..."
      } else{
        return name
      }
    },
    toggleInvitationDrawer() {
      this.invitationDrawerState = !this.invitationDrawerState;
    },
    toggleThumbNav() {
      // Triggered by the "more" button in the thumb nav
      this.thumbNavIsOpen = !this.thumbNavIsOpen;
    },
    getContentById(id) {
      return this.contentById[parseInt(id)] ? this.contentById[parseInt(id)] : this.contentById[-1];
    },
    formatDate(ds){
      return moment(ds).fromNow()
    },

    async gotoParent() {
      var rallyId = this.parentRallyId
      await this.fetchRalliesChannels({rallyId: rallyId});
      let parentRallyChannels = this.rallyChannelsByRallyId[rallyId]
      if (parentRallyChannels) {
        for (let c of parentRallyChannels) {
          if (c.attributes && (c.attributes.viewable || c.attributes.writable)) {
            this.$router.push(`/${rallyId}`);
            break; // This will exit the loop after the first matching channel is found
          }
        }
      } else {
        this.$router.push(`/${this.rallyId}`)
      }
    },
    log(message) {
      this.$log.debug(message);
    }
  }
};
</script>
