<template>
  <div>
  <component
    v-on:perform="perform"
    :class="elementClasses"
    :channel="channel"
    :content="content"
    :is="componentType"
    :key="content.id"
    :id="content.id"
    :contentType="this.content.attributes['content-type']"
    :rally="rally"
    :pUserId="pUserId"
    :contextShare="contextShare"
    :parentContextId="parentContextId"
    ref="contentComponent"

  />
  </div>
</template>

<script>
  import { mapState, mapActions, mapGetters } from "vuex";
  import ContentMixin from "./ContentMixin";
  import { eventBus } from "../main.js";
  import moment from "moment-timezone";
  import {
    kebabCase, router, findDefaultChannelOfTypeForRally, slugToTypeObjectMap  
  } from "../common";
  export default {
    mixins: [
      ContentMixin,
    ],
    props: {
      parentContextIds: Array,
      parentContextId: String,
      content: Object,
      pUserId: String,
      channel: Object,
      rally: Object,
      detailed: {
        type: Boolean,
        default: false,
      },
    },
    data() {
      return {
        editing: false,
        contextShare: null,
        isLoading: true,
        //isNewContent: false
      };
    },
    mounted() {
       this.$attrs.observer && this.$el ? this.$attrs.observer.observe(this.$el) : null
      this.contextShare =  this.content?.relationships['context-share'] && this.content?.relationships['context-share']?.data?.id ? this.sharesByShareId[this.content?.relationships['context-share']?.data?.id] : null
      this.isLoading = false
    },
    computed: {
     // ...mapState("rallies", ["rallyChannelsByRallyId", "allRalliesByRallyId"]), 
    ...mapState("contents", ["contentById"]),
    ...mapState("channels", ["channelsById"]),
      ...mapState("shares", ["sharesByShareId", "sharesByContentId"]),
      componentType() {
        return this.contentType + this.componentView;
      },
      componentView() {
        if ( this.editing || this.unsaved || this.content.editMode || this.content?.attributes['lifecycle'] ==  "new") {
          return 'Form';
        } else if ( this.contentType == 'WallMessage' && this.shared ) {
          return 'Shared';
        } else if (this.detailed ) {
          return 'Details';
        } else {
          return 'Listed';
        }
      },
      contentType() {
        //return this.content.attributes['type'];
        //let chanType =  this.channelsById[parseInt(this.$attrs.channelId)] &&
        //this.channelsById[parseInt(this.$attrs.channelId)].attributes ? this.channelsById[parseInt(this.$attrs.channelId)].attributes.type : this.content.attributes['type'];
        let chanType = this.channel.attributes.type
        let contentType =  this.content.attributes['type'];
        if (chanType == "Files"){
          //only one type for Folder, File may be of type File, Attachment, etc
          return contentType == "Folder" ? "Folder" : "File"
        } else if (chanType == "Gallery") {
          //only one type for Album, Photo may be of type Photo, Attachment, etc
          return contentType == "Album" ? "Album" : "Photo"
        } else if (contentType == "CalendarEvent" || contentType == "ChildPayableEvent"  || contentType == "DeadlineEvent" ){
          return "Event"
        } else {
          return this.content.attributes['type'];
        }
      },
      /*contentObjectId(){
        return this.content && this.content.id ?  this.content.id : this.content.tempKey
      },

      contentObject(){
        return this.content && this.content.tempKey?  this.contentById[this.tempKey] : this.content
      },*/
      lifeCycle(){
        return this.content.attributes['lifecycle'];
      },
      elementClasses() {
        const read = this.detailed ? 'details' : 'listed';
        const type = kebabCase(this.contentType);
        return `content ${read} ${type}`;
      },

      rallyId() {
        return this.rally.id
      },
      shares(){
        return this.sharesByContentId[parseInt(this.content.id)]
      },
      shared() {
        let share = this.sharesByShareId[this.content.relationships['context-share'].data.id]
        if (share && share.attributes ){
          return !share.attributes.owner
        }
        return false
      },
      unsaved() {
        return !this.content || this.content.id == null
        //return !this.content || (this.content.id == null &&  this.content.tempKey == null)
      },
    },
    created() {
      eventBus.on('editContent' , (p) => {
        if (this.content && (p == this.content.id))
        this.edit()
      })

      if (this.unsaved){
          this.tempKey = "TK" + Math.floor(Math.random() * 10000);
          this.content.attributes.title = ""
          this.content.attributes.body = "";
          this.content.attributes.type = this.contentType
          this.content.tempKey = this.tempKey
       // if ( this.contentType == 'WallMessage') {  
          this.rallyId = this.$route.params.rallyId
          this.createMessage({ newMessage: this.content, rallyId: this.rallyId, channelId: this.channelId, status:0, tempKey: this.tempKey});


        //} 
      }
    },
    methods: {
      ...mapActions("messages", ["createMessage", "saveMessage"]),
      ...mapActions("contents", ["createContents", "updateContent"]),
      cancel() {
        if ( this.unsaved ) {
          this.$emit('perform', 'cancelNewInline');
        } else {
          this.editing = false;
          //this.unsaved = false;
          this.content.editMode = false;
          if (this.content.attributes){
            this.content.attributes['lifecycle'] = ""
          }
        }
      },
      edit() {
        this.editing = true;
      },
      moveFolder(folder) {

      },
      openContainer(container) {
        this.$emit('perform', 'openContainer', container);
      },
      openContent(content) {
        this.$emit('perform', 'openContent', content);
      },
      perform(method, params) {
        if ( this[method] ) {
          params ? this[method](params) : this[method]();
        } else {
          this.$emit('perform', method, params);
        }
      },
      save() {
        if (this.$refs.contentComponent.$refs.cAttach){
          this.$refs.contentComponent.$refs.cAttach.saveAttachments()
        }
        if ( this.unsaved && this.contentType !== 'SignupEvent') {
          if ( this.contentType == 'WallMessage' ) { 
            var newContent = this.contentById[this.tempKey];
            newContent.attributes['title'] = this.content.attributes['title']
            newContent.attributes['body'] = this.content.attributes['body']
            this.saveMessage({ priority: this.content.attributes.priority ===true ? 1 : 4, message: newContent, rallyId: this.rallyId, channelId: this.channelId, status: 1});
            // this.$emit('perform', 'cancelNewInline');
            this.cancel()
          } else if ( this.contentType == 'SignupList' ||
              this.contentType == 'Board' ||
              this.contentType == 'Folder' ||
              this.contentType == 'Album' ||
              this.contentType == 'File' ) {
              var params = []
              params['type'] = this.contentType
              params['title'] = this.content.attributes['title']
              params['contentType']= 'Container'
              params['rallyId'] = this.$attrs.rallyId
              params['description'] = this.content.attributes['description']
              if (this.contentType == 'SignupList') {
                // --- SignupLists have the ability to set the status
                params['status'] = this.content.attributes['status']
              } else {
                params['status'] = 1
              }
              this.$emit('perform', 'saveNewInline', params);
          }
          else if ( this.contentType == 'Link' || this.contentType == 'SignupEvent' ) { 
            //"content"=>{"status"=>"1", "timezone"=>"Central Time (US & Canada)", "title"=>"test123signup", "description"=>"test123description", "max_participants"=>"3", "privacy"=>"1", "from_date"=>"01/07/2022", "from_time"=>"08:00 PM", "to_time"=>"09:00 PM", "to_date"=>"01/07/2022", "repeat_frequency"=>"no_repeat"}, "has_event"=>"1", "rally_id"=>"41405", "channel_id"=>"signup_lists", "id"=>"19044765"}
            var params2 = []
            params2['type'] = this.contentType
            params2['contentType'] = this.contentType
            params2['rallyId'] = this.rally.id
            if (this.contentType == 'Link'){
               params2['url'] = this.content.attributes['url']
            } else if (this.contentType == 'SignupEvent'){
               params2['contentType'] = 'Event'
               params2['maxParticipants'] = this.content.attributes['maxParticipants']
               params2['privacy'] = this.content.attributes['privacy']
               params2['title'] = this.content.attributes['title']
             //  params2['repeat_frequency'] =  this.content.attributes['repeat_frequency']
            } else if (this.contentType == 'Event') {
              //Started PUT "/38720/events/19025702" for 127.0.0.1 at 2022-01-30 17:58:38 -0600
              //Processing by ContentsController#update as HTML
              //Parameters: {"utf8"=>"✓", "authenticity_token"=>"V7As/m02qwUxLgbbCuvYfl3lQMk/33AqkyEIcUW0KCaY9CzpfNsmYMHBooieY4erjqflvB/fkMDtfL1AMAs5DA==", "content"=>{"status"=>"1", "timezone"=>"Central Time (US & Canada)", "title"=>"JT Web Event Jan 30", "location"=>"my house", "online_location"=>"https://zoom.us/11111", "online_location_password"=>"[FILTERED]", "description"=>"the description", "color"=>"#1c8291", "rsvp"=>"1", "all_day"=>"0", "from_date"=>"01/30/2022", "from_time"=>"05:00 PM", "to_time"=>"06:00 PM", "to_date"=>"01/30/2022", "repeat_frequency"=>"no_repeat"}, "override_url"=>"/38720/events", "commit"=>"Save", "rally_id"=>"38720", "channel_id"=>"events", "id"=>"19025702"}
               params2['contentType'] = 'Event'
               params2['color'] = this.content.attributes['hex']
               params2['status'] = this.content.attributes['status']
               params2['timezone'] = this.content.attributes['timezone']
               params2['location'] = this.content.attributes['location']
               params2['online_location'] = this.content.attributes['online_location']
               params2['privacy'] = "1"
               params2['title'] = this.content.attributes['title']
            } else if (this.contentType == "SignupEvent") {
               params2['all_day'] = this.content.attributes['all_day']
               params2['from_date'] = this.content.attributes['from_date']
               params2['to_date'] = this.content.attributes['to_date']
               params2['from_time'] = this.content.attributes['from_time']
               params2['to_time'] = this.content.attributes['to_time']
               params2['repeat_frequency'] = this.content.attributes['to_time']
               params2['update-all'] = this.content.attributes['update-all']
            }
            this.$emit('perform', 'saveNewInline', params2);
          } else {
            //alert('TODO: Create')
          }
        } else {
          if ( this.contentType == 'WallMessage' ){
           // this.$refs.contentComponent.$refs.cAttach.saveAttachments()
            this.saveMessage({ priority: this.content.attributes.priority ===true ? 1 : 4, message: this.content, rallyId: this.rallyId, channelId: this.channelId, status: 1});
            this.cancel()
          } else if (this.contentType == 'Event' ||
              this.contentType == 'SignupEvent' ||
              this.contentType == 'SignupList' ||
              this.contentType == 'Board' ||
              this.contentType == 'Folder' ||
              this.contentType == 'Album' ||
              this.contentType == 'File' ||
              this.contentType == 'Link' ){
              if(this.content.attributes.status === undefined) {
                this.content.attributes.status = "1"
              }
              this.content.editMode = false
              this.content.tempKey = null
              this.updateContent({id: this.content.id, content: this.content, rallyId: this.$attrs.rallyId})
              if (this.contentType == 'Event' ){
                  this.$emit('perform', 'navUp');
              } else {
                  this.cancel()
              }
          }
        }
      },
    },
  }
</script>
